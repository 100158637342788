/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This week's podcast features Michael Zuercher from Prismatic. We discuss his past venture, Zuercher Technologies, and how they took on a merger and eventual exit. Erick asks about how their company culture evolves while the company scales. Michael also shares some advice for new entrepreneurs."), "\n", React.createElement(_components.p, null, "Listen to the full interview on ", React.createElement(_components.a, {
    href: "https://codelation.com/from-idea-to-done/mergers-and-company-growth-ft-michael-zuercher/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Codelation"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
